import React, { Fragment, memo, useCallback, useMemo, useRef, useState } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Alert, Button, Card, Input, InputRef } from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  ServiceWideConfigData,
  ServiceWideFeatureConfig,
} from "@dinii-internal/universal-logic-legacy/service-wide-config/v1";
import { features } from "@dinii-internal/universal-logic-legacy/service-wide-config/v1/features";
import { collection, doc, setDoc } from "firebase/firestore";

import { MainLayout } from "components/Layout/MainLayout";
import { Spacer } from "components/Spacer";
import { useShops } from "hooks/useShops";
import { firestore } from "libs/firebase";

import { EditServiceWideConfigModal } from "./EditServiceWideConfigModal";
import { useVerification } from "./useVerification";

const serviceWideConfigDoc = doc<ServiceWideConfigData, ServiceWideConfigData>(
  collection(firestore, "serviceWideConfig"),
  "0",
);

const VerificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ServiceWideConfig = memo(() => {
  const { getShopName, companies } = useShops();
  const [data, loadingData, _error, _snapshot, refetch] = useDocumentDataOnce(serviceWideConfigDoc);

  const { status, verify, clearVerification } = useVerification();
  const verified = status === "verified";
  const verificationCodeInputRef = useRef<InputRef>(null);
  const handleVerify = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      verify(verificationCodeInputRef.current?.input?.value ?? "");
    },
    [verify],
  );

  const [selectedFeature, setSelectedFeature] = useState<ServiceWideFeatureConfig["name"] | null>(
    null,
  );

  const handleSelectFeature = useCallback(
    async (feature: ServiceWideFeatureConfig["name"] | null) => {
      await refetch();
      setSelectedFeature(feature);
    },
    [refetch, setSelectedFeature],
  );

  const unselectFeature = useCallback(() => {
    setSelectedFeature(null);
  }, []);

  const featuresData = useMemo(() => data?.features ?? [], [data]);

  const featuresMap = useMemo(
    () =>
      new Map(featuresData.map((feature) => [feature.name, feature as ServiceWideFeatureConfig])),
    [featuresData],
  );

  const [{ loading: submitting }, handleSave] = useAsyncFn(
    async (config: ServiceWideFeatureConfig) => {
      await setDoc(serviceWideConfigDoc, {
        features: [...featuresData.filter((feature) => feature.name !== config.name), config],
      });
      await refetch();
      setSelectedFeature(null);
    },
    [featuresData, refetch],
  );

  return (
    <MainLayout title="Service Wide Config" withoutEmergencyRoleIdReplacement>
      <Alert
        type="warning"
        description="この設定はサービス全体に影響を与えるものです。設定の意味を理解しているエンジニアのみが変更するようにしてください。"
      />

      <Spacer inline size={16} />

      <VerificationContainer>
        <div>
          <strong>{verified ? "認証済みです" : "認証が必要です"}</strong>
        </div>
        <Spacer inline size={8} />
        {verified ? (
          <Button onClick={clearVerification}>認証をクリア</Button>
        ) : (
          <form onSubmit={handleVerify}>
            <Input
              type="password"
              disabled={status === "uninitialized"}
              ref={verificationCodeInputRef}
            />
          </form>
        )}
      </VerificationContainer>

      <Spacer size={16} />

      {submitting || loadingData
        ? null
        : Object.values(features).map(({ name, description }) => (
            <Fragment key={name}>
              <Card
                title={`${name} - 設定${featuresMap.has(name) ? "あり" : "なし"}`}
                size="small"
                extra={
                  <Button
                    type="text"
                    icon={verified ? <EditOutlined /> : <InfoCircleOutlined />}
                    onClick={() => handleSelectFeature(name)}
                  >
                    {verified ? "設定する" : "設定を見る"}
                  </Button>
                }
              >
                <p>
                  {description.split("\n").map((line, index) => (
                    <div key={index}>{line.trim()}</div>
                  ))}
                </p>
              </Card>
              <Spacer size={24} />
            </Fragment>
          ))}

      <EditServiceWideConfigModal
        featureName={selectedFeature}
        disabled={!verified}
        defaultValue={(selectedFeature && featuresMap.get(selectedFeature)) || null}
        companies={companies}
        getShopName={getShopName}
        onSave={handleSave}
        onDismiss={unselectFeature}
      />
    </MainLayout>
  );
});
