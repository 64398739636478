import React, { useCallback, useMemo } from "react";
import dayjs from "dayjs";

import { MainLayout } from "components/Layout/MainLayout";
import { useCashRegisterLocalOperationRecordAggregation } from "hooks/useCashRegisterLocalOperationRecords";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useSearchParams } from "hooks/useSearchParams";
import { useSelectedShop } from "hooks/useSelectedShop";
import { FilterConditions, ShopStatusFilter } from "pages/ShopStatuses/ShopStatusFilter";
import { ShopSetInput } from "types/graphql";

import { useShopStatusesGetShopsQuery, useShopStatusesUpdateShopMutation } from "./queries";
import { ShopStatusTable } from "./ShopStatusTable";
import { ShopStatus } from "./types";

export const filterShopStatus = (
  shopStatus: ShopStatus[],
  { isActive, isAlertEnabled, hasUnsettledLocalOperation, companyId, shopId }: FilterConditions,
) =>
  shopStatus.filter(
    (shop) =>
      (companyId === undefined || companyId === shop.companyId) &&
      (shopId === undefined || shopId === shop.shopId) &&
      (hasUnsettledLocalOperation === undefined ||
        Boolean(shop.localOperationRecords) === hasUnsettledLocalOperation) &&
      (isActive === undefined || shop.isDuringBusinessHours === isActive) &&
      (isAlertEnabled === undefined || shop.enableAlert === isAlertEnabled),
  );

const getBooleanDefaultTrue = (value: string | undefined) =>
  value === "false" ? false : value === "none" ? undefined : true;

export const ShopStatuses = () => {
  const { setSelectedShop } = useSelectedShop();
  const { getSearchParam, setSearchParam } = useSearchParams<
    "companyId" | "shopId" | "hasUnsettledLocalOperation" | "isActive" | "isAlertEnabled"
  >();
  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({
      shopId: getSearchParam("shopId"),
      companyId: getSearchParam("companyId"),
      hasUnsettledLocalOperation: getBooleanDefaultTrue(
        getSearchParam("hasUnsettledLocalOperation") ?? "none",
      ),
      isActive: getBooleanDefaultTrue(getSearchParam("isActive")),
      isAlertEnabled: getBooleanDefaultTrue(getSearchParam("isAlertEnabled")),
    });

  const {
    data: shopStatusesData,
    loading: loadingShopStatuses,
    refetch: refetchShopStatuses,
  } = useShopStatusesGetShopsQuery();

  const { data: localOperationRecordAggregation, loading: loadingLocalOperationRecordAggregation } =
    useCashRegisterLocalOperationRecordAggregation({
      filter: filterConditions.companyId
        ? { companyIds: [filterConditions.companyId] }
        : filterConditions.shopId
          ? { shopId: filterConditions.shopId }
          : (shopStatusesData?.shop.length ?? 0) > 0
            ? { all: true }
            : { skip: true },
    });
  const shopIdToLocalOperationRecordAggregationMap = useMemo(
    () => new Map(localOperationRecordAggregation.shops.map((item) => [item.shopId, item])),
    [localOperationRecordAggregation.shops],
  );

  const shopStatuses = useMemo<ShopStatus[]>(
    () =>
      (shopStatusesData?.shop ?? []).map((shop) => {
        const lastCloseCashRegisterTime = shop.closeCashRegisters[0]
          ? dayjs(shop.closeCashRegisters[0].createdAt)
          : null;
        const lastTableUserActivatedTime = shop.tableUsers[0]
          ? dayjs(shop.tableUsers[0].activatedAt)
          : null;
        const isDuringBusinessHours =
          (dayjs().diff(lastTableUserActivatedTime, "hour") < 24 &&
            lastTableUserActivatedTime?.isAfter(lastCloseCashRegisterTime)) ??
          false;

        return {
          ...shop,
          localOperationRecords:
            shopIdToLocalOperationRecordAggregationMap.get(shop.shopId) ?? null,
          isDuringBusinessHours,
          lastCloseCashRegisterTime,
          lastTableUserActivatedTime,
        };
      }),
    [shopIdToLocalOperationRecordAggregationMap, shopStatusesData?.shop],
  );

  const [updateShopMutation, { loading: loadingUpdateShop }] = useShopStatusesUpdateShopMutation({
    onCompleted: () => refetchShopStatuses(),
  });

  const updateShop = useCallback(
    (input: { shopId: string } & ShopSetInput) => {
      const shopId = input.shopId;
      updateShopMutation({ variables: { shopId, input } });
    },
    [updateShopMutation],
  );

  const loading = loadingShopStatuses || loadingUpdateShop;

  const filteredShopStatuses = useMemo(
    () => filterShopStatus(shopStatuses, filterConditions),
    [shopStatuses, filterConditions],
  );

  const handleUpdateFilterCondition = useCallback(
    (conditions: FilterConditions) => {
      if ("companyId" in conditions) {
        setSelectedShop(null);
        updateFilterCondition({ shopId: undefined, ...conditions });
        setSearchParam("shopId", null);
        setSearchParam("companyId", conditions.companyId);
        return;
      }

      if ("shopId" in conditions) {
        setSelectedShop(conditions.shopId ?? null);
        updateFilterCondition({ companyId: undefined, ...conditions });
        setSearchParam("shopId", conditions.shopId);
        setSearchParam("companyId", null);
        return;
      }

      if ("isActive" in conditions) {
        updateFilterCondition({ ...conditions });
        setSearchParam(
          "isActive",
          conditions.isActive === undefined ? "none" : conditions.isActive.toString(),
        );

        return;
      }

      if ("isAlertEnabled" in conditions) {
        updateFilterCondition({ ...conditions });
        setSearchParam(
          "isAlertEnabled",
          conditions.isAlertEnabled === undefined ? "none" : conditions.isAlertEnabled.toString(),
        );
        return;
      }

      updateFilterCondition(conditions);
    },
    [updateFilterCondition, setSelectedShop, setSearchParam],
  );

  const handleClearFilterCondition = useCallback(() => {
    setSelectedShop(null);
    clearFilterConditions();
    setSearchParam("shopId", null);
    setSearchParam("companyId", null);
  }, [clearFilterConditions, setSelectedShop, setSearchParam]);

  return (
    <MainLayout title={`店舗状況 (${filteredShopStatuses.length})`}>
      <ShopStatusFilter
        shopStatuses={shopStatuses}
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={handleUpdateFilterCondition}
        clearFilterConditions={handleClearFilterCondition}
      />

      <ShopStatusTable
        shopStatuses={filteredShopStatuses}
        loading={loading}
        loadingLocalOperationRecordAggregation={loadingLocalOperationRecordAggregation}
        updateShop={updateShop}
      />
    </MainLayout>
  );
};
