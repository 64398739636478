import {
  BodyOfCreateOnSitePayment,
  BodyOfCreateOrder,
  BodyOfDisposeNotDispatchedData,
  BodyOfStartPlan,
  BodyOfStartTableUser,
  SummaryOfCreateOnSitePayment,
  SummaryOfCreateOrder,
  SummaryOfDisposeNotDispatchedData,
  SummaryOfStartPlan,
  SummaryOfStartTableUser,
} from "./concretes";
import { LocalOperationRecord } from "./local-operation-record";

export type RecordTypeOfLocalOperation = RecordOfLocalOperation["type"];
export type RecordOfLocalOperation =
  | RecordOfCreateOnSitePayment
  | RecordOfCreateOrder
  | RecordOfStartPlan
  | RecordOfStartTableUser
  | RecordOfDisposeNotDispatchedData;

export type RecordOfCreateOnSitePayment = LocalOperationRecord<{
  type: "createOnSitePayment";
  body: BodyOfCreateOnSitePayment;
  summary: SummaryOfCreateOnSitePayment;
}>;

export type RecordOfCreateOrder = LocalOperationRecord<{
  type: "createOrder";
  body: BodyOfCreateOrder;
  summary: SummaryOfCreateOrder;
}>;

export type RecordOfStartPlan = LocalOperationRecord<{
  type: "startPlan";
  body: BodyOfStartPlan;
  summary: SummaryOfStartPlan;
}>;

export type RecordOfStartTableUser = LocalOperationRecord<{
  type: "startTableUser";
  body: BodyOfStartTableUser;
  summary: SummaryOfStartTableUser;
}>;

export type RecordOfDisposeNotDispatchedData = LocalOperationRecord<{
  type: "disposeNotDispatchedData";
  body: BodyOfDisposeNotDispatchedData;
  summary: SummaryOfDisposeNotDispatchedData;
}>;

export const getLocalOperationRecordTypeLabelByRecordType = (type: RecordTypeOfLocalOperation) =>
  ({
    startTableUser: "卓立ち上げ",
    startPlan: "プラン開始",
    createOrder: "注文追加",
    createOnSitePayment: "会計",
    disposeNotDispatchedData: "データ破棄",
  })[type] ?? null;
