import { unreachable } from "dinii-self-js-lib/unreachable";
import {
  and,
  collection,
  collectionGroup,
  FirestoreDataConverter,
  getDocsFromServer,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  where,
} from "firebase/firestore";
import { err, Ok, ok } from "neverthrow";

import { firestore } from "libs/firebase";

import {
  FirestoreLocalOperationRecord,
  LocalOperationRecord,
  LocalOperationRecordAbstract,
} from "./types/local-operation-record";
import { RecordOfLocalOperation } from "./types";

const readonlyConverter: FirestoreDataConverter<
  FirestoreLocalOperationRecord,
  LocalOperationRecord<LocalOperationRecordAbstract>
> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data() as FirestoreLocalOperationRecord;
    return {
      ...data,
      // NOTE: モニター上では body と summary だけ参照する
      body: JSON.parse(data.body),
      summary: JSON.parse(data.summary),
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toFirestore: () => ({}) as any,
};

export const fetchRecordsByShopId = async ({ shopId }: { shopId: string }) => {
  try {
    const result = await getDocsFromServer(
      query(
        collection(firestore, `shops/${shopId}/localOperationRecords`),
        where("releasedAt", "==", null),
        orderBy("createdAt", "desc"),
      ).withConverter(readonlyConverter),
    );

    const records = result.docs.map((doc) => doc.data() as unknown as RecordOfLocalOperation);

    return ok({ records });
  } catch (thrown) {
    console.error(thrown);

    return err({ thrown });
  }
};

const pickValue = <K extends keyof RecordOfLocalOperation>(doc: QueryDocumentSnapshot, key: K) =>
  doc.get(key) as FirestoreLocalOperationRecord[K];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InferOk<T> = T extends T ? (T extends Ok<infer U, any> ? U : never) : never;
export type LocalOperationRecordForAggregation = InferOk<
  Awaited<ReturnType<typeof fetchRecordsForAggregation>>
>["records"][number];

const limitCount = 1000;

export type AggregationQueryFilterInput =
  | { shopId: string }
  | { companyIds: string[] }
  | { skip: true }
  | { all: true };

const normalizeFilter = (input: AggregationQueryFilterInput) => {
  if ("all" in input && input.all) {
    return { type: "all" as const };
  }

  if ("shopId" in input) {
    return { type: "shop" as const, shopId: input.shopId };
  }

  if ("companyIds" in input && input.companyIds.length > 0) {
    return { type: "company" as const, companyIds: input.companyIds };
  }

  return { type: "skip" as const };
};

export const fetchRecordsForAggregation = async (input: {
  cursor: Timestamp;
  filter: AggregationQueryFilterInput;
}) => {
  const filter = normalizeFilter(input.filter);

  if (filter.type === "skip") {
    return ok({ records: [], done: true });
  }

  try {
    const result = await getDocsFromServer(
      // NOTE: body や summary へはアクセスしないので converter は利用しない
      query(
        collectionGroup(firestore, "localOperationRecords"),
        and(
          ...(filter.type === "all"
            ? []
            : filter.type === "shop"
              ? [where("shopId", "==", filter.shopId)]
              : filter.type === "company"
                ? [where("companyId", "in", filter.companyIds.slice(0, 30))]
                : unreachable(filter, "fetchRecordsForAggregation filter")),
          where("__serverTimestamp", "<=", input.cursor),
          // NOTE: 詳細は店舗ごとの表示でカバーする。ここでは未解消のデータの有無と件数にのみ関心を持つ
          where("settlement", "==", null),
        ),
        orderBy("__serverTimestamp", "desc"),
        limit(limitCount),
      ),
    );

    const records = result.docs.map((doc) => ({
      id: pickValue(doc, "id"),
      type: pickValue(doc, "type") as RecordOfLocalOperation["type"],
      shopId: pickValue(doc, "shopId"),
      issuer: pickValue(doc, "issuer"),
      createdAt: pickValue(doc, "createdAt"),
      __serverTimestamp: pickValue(doc, "__serverTimestamp"),
    }));

    return ok({ records, done: records.length !== limitCount });
  } catch (thrown) {
    console.error(thrown);

    return err({ thrown });
  }
};
