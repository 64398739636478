import { message } from "antd";
import dayjs from "dayjs";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { err, ok } from "neverthrow";

import { auth, firestore } from "libs/firebase";

import { LocalOperationRecordSettlement } from "./types/local-operation-record";
import { RecordOfLocalOperation } from "./types";

export const setLocalOperationRecordSettlement = async ({
  record,
  status,
  quiet,
}: {
  record: RecordOfLocalOperation;
  status: LocalOperationRecordSettlement["status"];
  quiet?: boolean;
}) => {
  const { id: recordId, shopId } = record;

  try {
    const ref = doc(firestore, `shops/${shopId}/localOperationRecords/${recordId}`);
    const existing = await getDoc(ref);
    if (!existing.exists()) {
      if (!quiet) {
        message.warning("対象の記録が見つかりませんでした");
      }
      return err({ kind: "document-not-found" as const });
    }

    const existingSettlement = existing.get("settlement");
    if (existingSettlement) {
      if (!quiet) {
        message.warning("すでに解決済みのデータです");
      }
      return err({
        kind: "already-settled" as const,
        settlement: existingSettlement as LocalOperationRecordSettlement,
      });
    }

    const email = auth.currentUser?.email ?? "unknown";
    await updateDoc(ref, {
      settlement: {
        status,
        actor: "admin",
        description: `operated by ${email}`,
        settledAt: dayjs().toISOString(),
      } satisfies LocalOperationRecordSettlement,
    });

    if (!quiet) {
      message.warning("処理に成功しました");
    }

    return ok(null);
  } catch (thrown) {
    if (!quiet) {
      message.warning("処理に失敗しました");
    }
    return err({ kind: "thrown" as const, thrown });
  }
};
