import React, { memo, useCallback, useMemo } from "react";
import JSONPretty from "react-json-pretty";
import styled from "styled-components";
import { Button } from "antd";
import { TableProps } from "antd/lib/table";
import dayjs from "dayjs";
import { jsonPrettyTheme } from "util/json-pretty-theme";

import { AbbreviatedUuid } from "components/AbbreviatedUuid";
import { Spacer } from "components/Spacer";
import { Table } from "components/Table";
import { setLocalOperationRecordSettlement } from "hooks/useCashRegisterLocalOperationRecords/mutate-local-operation-record";
import {
  getLocalOperationRecordTypeLabelByRecordType,
  RecordOfLocalOperation,
} from "hooks/useCashRegisterLocalOperationRecords/types";
import { usePagination } from "hooks/usePagination";

type Props = {
  loading?: boolean;
  records: RecordOfLocalOperation[];
  onDataRefresh: () => void;
};

const TableStyleOverride = styled.div`
  .ant-table-content {
    overflow: visible !important;
  }
`;

const JsonContainer = styled.div`
  background: inherit;
  position: absolute;
  left: 16px;
  top: 16px;
  right: 16px;
  bottom: 16px;
  border-radius: 8px;
  overflow: scroll;
  transition: 0.1s;
  &:hover {
    min-width: 100%;
    min-height: 100%;
    padding: 16px;
    left: 0;
    top: 0;
    right: unset;
    bottom: unset;
    z-index: 1000;
    border: 1px solid #d9d9d9;
  }
`;

export const LocalOperationRecordTable = memo<Props>(({ loading, records, onDataRefresh }) => {
  const [pagination, setPagination] = usePagination();

  const handleDisposeLocalOperationRecord = useCallback(
    async ({ record }: { record: RecordOfLocalOperation }) => {
      if (
        confirm(
          [
            // TODO: 未送信データの破棄を店舗側で行えるようにしたらその部分にも言及する
            `選択した ${getLocalOperationRecordTypeLabelByRecordType(record.type)} の記録を破棄します。`,
            `🚨 未送信状態ではなくなりますが、サーバーにはデータが記録されません。`,
            `破棄して終わりではなく、必ず店舗にデータの打ち直しなどの案内をしてください。`,
          ].join("\n"),
        )
      ) {
        await setLocalOperationRecordSettlement({ record, status: "disposed" });
        // NOTE: 失敗した場合最新のデータを元にリトライすべき。成功した場合は成功した状態のデータを再取得するべき。
        onDataRefresh();
      }
    },
    [onDataRefresh],
  );

  const columns = useMemo<TableProps<RecordOfLocalOperation>["columns"]>(
    () => [
      {
        title: "ID",
        width: 100,
        render(_, { id }) {
          return <AbbreviatedUuid uuid={id} />;
        },
      },
      {
        title: "種別",
        width: 100,
        render(_, { type }) {
          return getLocalOperationRecordTypeLabelByRecordType(type) ?? "不明";
        },
      },
      {
        title: "作成時刻",
        width: 180,
        render(_, { createdAt }) {
          return dayjs(createdAt).format("YYYY/MM/DD HH:mm:ss");
        },
        defaultSortOrder: "descend" as const,
        sorter: (a, b) => dayjs(a.createdAt ?? 0).diff(dayjs(b.createdAt ?? 0)),
      },
      {
        title: "データ",
        width: 200,
        render(_, { body }) {
          return (
            <>
              <Spacer width={0} height={100} />
              <JsonContainer>
                <JSONPretty data={body} theme={jsonPrettyTheme} />
              </JsonContainer>
            </>
          );
        },
      },
      {
        title: "サマリ",
        render(_, { summary }) {
          return (
            <>
              <Spacer width={0} height={100} />
              <JsonContainer>
                <JSONPretty data={summary} theme={jsonPrettyTheme} />
              </JsonContainer>
            </>
          );
        },
      },
      {
        title: "",
        width: 100,
        align: "center",
        render(_, record) {
          return record.settlement ? (
            <>
              {{ resolved: "解決済み", disposed: "破棄済み" }[record.settlement.status]}
              <br />
              (by {{ shop: "店舗", admin: "ダイニー" }[record.settlement.actor]})
            </>
          ) : (
            <>
              未解決
              <Button danger onClick={() => handleDisposeLocalOperationRecord({ record })}>
                破棄する
              </Button>
            </>
          );
        },
      },
    ],
    [handleDisposeLocalOperationRecord],
  );

  return (
    <TableStyleOverride>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={records}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    </TableStyleOverride>
  );
});
