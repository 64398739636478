import React, { memo } from "react";
import styled from "styled-components";
import { Button, Tooltip } from "antd";
import { ExportOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { Spacer } from "components/Spacer";
import { ShopLocalOperationAggregation } from "hooks/useCashRegisterLocalOperationRecords/local-operation-record-aggregation";
import { getLocalOperationRecordTypeLabelByRecordType } from "hooks/useCashRegisterLocalOperationRecords/types";

const InfoContainer = styled.span`
  padding: 8px;
  margin: -8px;
  font-weight: 600;
  color: red;
`;

const GridTable = styled.dl`
  display: grid;
  grid-template-columns: 100px 40px;
  grid-auto-flow: row;
  margin: 0;

  dt,
  dd {
    margin: 0;
  }
`;

const GridTableItem = ({
  label,
  content,
}: {
  label: React.ReactNode;
  content: React.ReactNode;
}) => (
  <>
    <dt>{label}</dt>
    <dd>: {content}</dd>
  </>
);

const TimestampRange = styled.div`
  text-align: center;
`;

type Props = {
  loading: boolean;
  data: ShopLocalOperationAggregation | null;
};

export const ShopLocalOperationRecordSummary = memo<Props>(({ loading, data }) => {
  if (!data) {
    return (
      <div>
        {loading ? <div>ロード中…</div> : null}
        データなし
      </div>
    );
  }

  const { shopId, issuers, oldestRecordTimestamp, latestRecordTimestamp, countsByType } = data;
  const totalCount =
    countsByType.startTableUser +
    countsByType.startPlan +
    countsByType.createOrder +
    countsByType.createOnSitePayment;

  return (
    <>
      {loading ? <div>ロード中…</div> : null}
      <Tooltip
        title={
          <div>
            {issuers.length > 1 ? <strong>複数レジの疑いアリ</strong> : null}
            <GridTable>
              <GridTableItem
                label={getLocalOperationRecordTypeLabelByRecordType("startTableUser")}
                content={`${countsByType.startTableUser}件`}
              />
              <GridTableItem
                label={getLocalOperationRecordTypeLabelByRecordType("startPlan")}
                content={`${countsByType.startPlan}件`}
              />
              <GridTableItem
                label={getLocalOperationRecordTypeLabelByRecordType("createOrder")}
                content={`${countsByType.createOrder}件`}
              />
              <GridTableItem
                label={getLocalOperationRecordTypeLabelByRecordType("createOnSitePayment")}
                content={`${countsByType.createOnSitePayment}件`}
              />
              <GridTableItem
                label={getLocalOperationRecordTypeLabelByRecordType("disposeNotDispatchedData")}
                content={`${countsByType.disposeNotDispatchedData}件`}
              />
            </GridTable>
            <TimestampRange>
              <div>{oldestRecordTimestamp.format("YYYY/MM/DD HH:mm:ss")}</div>
              <div>〜</div>
              <div>{latestRecordTimestamp.format("YYYY/MM/DD HH:mm:ss")}</div>
            </TimestampRange>
          </div>
        }
      >
        <InfoContainer>
          <InfoCircleOutlined />
          <Spacer size={5} inline />
          合計 {totalCount} 件
          <Spacer size={5} inline />
          <Button
            type="ghost"
            icon={<ExportOutlined />}
            href={`/shop-local-operation-records?shopId=${shopId}`}
            target="_blank"
          />
        </InfoContainer>
      </Tooltip>
    </>
  );
});
