import dayjs from "dayjs";

import { LocalOperationRecordForAggregation } from "./fetch-local-operation-records";
import { RecordOfLocalOperation } from "./types";

type ShopAccumulator = {
  shopId: string;
  issuers: Set<string>;
  idsByType: Record<RecordOfLocalOperation["type"], Set<string>>;
  oldestRecordTimestamp: string;
  latestRecordTimestamp: string;
};

type Accumulator = {
  shops: Map<string, ShopAccumulator>;
};

type Aggregator = ReturnType<typeof createLocalOperationRecordAggregator>;
export type LocalOperationRecordAggregationData = ReturnType<Aggregator["snapshot"]>;
export type ShopLocalOperationAggregation = LocalOperationRecordAggregationData["shops"][number];

export const createLocalOperationRecordAggregator = () => {
  const rootAcc: Accumulator = { shops: new Map() };

  const ensureShopAccumulator = (record: LocalOperationRecordForAggregation) => {
    const existing = rootAcc.shops.get(record.shopId);
    if (existing) {
      return existing;
    }

    const created: ShopAccumulator = {
      shopId: record.shopId,
      issuers: new Set(),
      idsByType: {
        createOnSitePayment: new Set(),
        createOrder: new Set(),
        disposeNotDispatchedData: new Set(),
        startPlan: new Set(),
        startTableUser: new Set(),
      },
      oldestRecordTimestamp: record.createdAt,
      latestRecordTimestamp: record.createdAt,
    };
    rootAcc.shops.set(record.shopId, created);

    return created;
  };

  const append = (record: LocalOperationRecordForAggregation) => {
    const acc = ensureShopAccumulator(record);

    acc.issuers.add(record.issuer);

    // NOTE: レジ側の仕様変更を多少は吸収できるように型を信用しきらない実装をしている
    if (record.type in acc.idsByType) {
      acc.idsByType[record.type].add(record.id);
    }

    // NOTE: 未送信データ破棄はタイムスタンプの集計に含めない
    if (record.type !== "disposeNotDispatchedData") {
      // NOTE: 同じ ISO 8601 フォーマットのタイムスタンプなので文字列比較でも有効な結果が得られる
      if (acc.latestRecordTimestamp < record.createdAt) {
        acc.latestRecordTimestamp = record.createdAt;
      }
      // NOTE: 同じ ISO 8601 フォーマットのタイムスタンプなので文字列比較でも有効な結果が得られる
      if (acc.oldestRecordTimestamp > record.createdAt) {
        acc.oldestRecordTimestamp = record.createdAt;
      }
    }
  };

  const snapshot = () => ({
    shops: Array.from(rootAcc.shops.values()).map((acc) => ({
      shopId: acc.shopId,
      issuers: Array.from(acc.issuers),
      countsByType: ((): Record<RecordOfLocalOperation["type"], number> => ({
        createOnSitePayment: acc.idsByType.createOnSitePayment.size,
        createOrder: acc.idsByType.createOrder.size,
        disposeNotDispatchedData: acc.idsByType.disposeNotDispatchedData.size,
        startPlan: acc.idsByType.startPlan.size,
        startTableUser: acc.idsByType.startTableUser.size,
      }))(),
      latestRecordTimestamp: dayjs(acc.latestRecordTimestamp),
      oldestRecordTimestamp: dayjs(acc.oldestRecordTimestamp),
    })),
  });

  return { append, snapshot };
};
