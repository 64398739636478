import React, { useMemo } from "react";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useCashRegisterLocalOperationRecordsOfShop } from "hooks/useCashRegisterLocalOperationRecords";
import { RecordOfLocalOperation } from "hooks/useCashRegisterLocalOperationRecords/types";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useSearchParams } from "hooks/useSearchParams";
import {
  FilterConditions,
  LocalOperationRecordFilter,
} from "pages/ShopLocalOperationRecords/LocalOperationRecordFilter";

import { LocalOperationRecordTable } from "./LocalOperationRecordTable";

export const filterRecord = (
  records: RecordOfLocalOperation[],
  { type, status }: FilterConditions,
) =>
  records.filter(
    (record) =>
      (type === undefined || record.type === type) &&
      (status === undefined ||
        (status === "unsettled" && record.settlement === null) ||
        record.settlement?.status === status),
  );

export const LocalOperationRecords = () => {
  const { getSearchParam } = useSearchParams<"shopId">();
  const shopId = getSearchParam("shopId") ?? null;

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const { loading, records, refetch } = useCashRegisterLocalOperationRecordsOfShop({ shopId });

  const filteredRecords = useMemo(
    () => filterRecord(records ?? [], filterConditions),
    [records, filterConditions],
  );

  return (
    <MainLayout title="店舗の未送信データ一覧">
      <ShopSelector />

      <Spacer size={16} />

      <LocalOperationRecordFilter
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={updateFilterCondition}
        clearFilterConditions={clearFilterConditions}
      />

      <LocalOperationRecordTable
        records={filteredRecords}
        loading={loading}
        onDataRefresh={refetch}
      />
    </MainLayout>
  );
};
