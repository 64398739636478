import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { timestampLike } from "dinii-self-js-lib/timestamp";
import { Timestamp } from "firebase/firestore";
import { err } from "neverthrow";

import { sleep } from "libs/sleep";

import {
  AggregationQueryFilterInput,
  fetchRecordsByShopId,
  fetchRecordsForAggregation,
} from "./fetch-local-operation-records";
import {
  createLocalOperationRecordAggregator,
  LocalOperationRecordAggregationData,
} from "./local-operation-record-aggregation";
import { RecordOfLocalOperation } from "./types";

export const useCashRegisterLocalOperationRecordsOfShop = ({
  shopId,
}: {
  shopId: string | null;
}) => {
  const [loading, setLoading] = useState(Boolean(shopId));
  const [records, setRecords] = useState<RecordOfLocalOperation[] | null>(null);

  const fetchRecords = useCallback(async (shopId: string) => {
    setLoading(true);

    const result = await fetchRecordsByShopId({ shopId });

    if (result.isOk()) {
      const { records } = result.value;
      setRecords(records);
    }

    setLoading(false);
  }, []);

  const refetch = useCallback(() => {
    if (!shopId) {
      return err({ noShopId: true });
    }
    return fetchRecords(shopId);
  }, [fetchRecords, shopId]);

  useEffect(() => {
    // NOTE: shopId 変更の際はまずデータをクリアする
    setRecords(null);

    if (!shopId) {
      return;
    }

    void fetchRecords(shopId);
  }, [fetchRecords, shopId]);

  return { loading, records, refetch };
};

export const useCashRegisterLocalOperationRecordAggregation = (props: {
  filter: AggregationQueryFilterInput;
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<LocalOperationRecordAggregationData>({ shops: [] });

  // NOTE: 不要な再評価を防ぐための手頃な方法として JSON を経由する方法を使っている。モニターなので軽率にこの方法を採用しているが、
  // 他のプロダクトでは別の方法を検討するようにすること。
  const filterJson = JSON.stringify(props.filter);
  const filter = useMemo(() => JSON.parse(filterJson) as AggregationQueryFilterInput, [filterJson]);

  useEffect(() => {
    const aggregator = createLocalOperationRecordAggregator();

    let canceled = false;
    const cancellationSymbol = Symbol();
    const assertCancellation = () => {
      if (canceled) {
        throw cancellationSymbol;
      }
    };

    (async () => {
      setLoading(true);

      let cursor = Timestamp.fromDate(dayjs().add(1, "day").endOf("date").toDate());
      const cursorEnd = Timestamp.fromDate(dayjs().subtract(14, "days").startOf("date").toDate());
      // TODO: 重複集計の問題が発生したら回避用に cursor と同じタイムスタンプを持つものの ID を控えるようにする
      //       また、同じタイムスタンプで 1000 件以上のレコードがある場合は offset を設定するようなことを考える必要が出てくる。
      // const edgeRecordIds = new Set<string>();

      try {
        while (timestampLike.compare(cursor, ">", cursorEnd)) {
          assertCancellation();
          setData(aggregator.snapshot());

          const result = await fetchRecordsForAggregation({ cursor, filter });
          if (result.isErr()) {
            break;
          }

          assertCancellation();
          const sleepPromise = sleep(100);
          const { records, done } = result.value;

          const oldestTimestamp = records[records.length - 1]?.__serverTimestamp;
          if (!oldestTimestamp) {
            break;
          }
          const nextCursor = new Timestamp(oldestTimestamp.seconds, oldestTimestamp.nanoseconds);

          // TODO: 理想としては fetch 中にこの処理をして全体の処理時間を最適化したい
          for (const record of records) {
            aggregator.append(record);
          }

          if (done) {
            break;
          }
          cursor = nextCursor;

          await sleepPromise;
          assertCancellation();
        }
      } catch (error) {
        if (error === cancellationSymbol) {
          // NOTE: すでに他のクエリが走っている可能性があるため状態への関与は一切しない
          return;
        }

        console.error(error);
      }

      setData(aggregator.snapshot());
      setLoading(false);
    })();

    return () => {
      canceled = true;
    };
  }, [filter]);

  return { loading, data };
};
