import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Input, InputNumber, message, Select } from "antd";
import { CaretDownFilled, CaretUpFilled, DeleteOutlined } from "@ant-design/icons";
import {
  Condition,
  conditionFilters,
} from "@dinii-internal/universal-logic-legacy/service-wide-config/v1/condition";
import { zeroIfNaN } from "dinii-self-js-lib/number";
import { isOneOfUnion, isSomeOfUnion } from "dinii-self-js-lib/object-types";
import { isNotNullish } from "dinii-self-js-lib/types";
import { unreachable } from "dinii-self-js-lib/unreachable";

import { InputCustomFormatText } from "components/Input/InputCustomFormatText";
import { Spacer } from "components/Spacer";
import { Company } from "hooks/useShops/types";

type Props = {
  disabled: boolean;
  index: number;
  value: Condition;
  companies: Company[];
  getShopName: (shopId: string) => string | undefined;
  removeCondition: (index: number) => void;
  setCondition: (index: number, condition: Condition) => void;
  moveCondition: (from: number, to: number) => void;
};

const Container = styled.div`
  width: 800px;
`;

const ShopSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const InputCondition = memo<Props>(
  ({
    disabled,
    index,
    value: condition,
    moveCondition,
    removeCondition,
    setCondition,
    companies,
    getShopName,
  }) => {
    const needsShopOptions = isSomeOfUnion(condition, ["shopIds", "disabledShopIds"]);
    const shopOptions = useMemo(
      () =>
        needsShopOptions
          ? companies.map(({ name: companyName, shops }) => ({
              label: companyName,
              options: shops.map(({ shopId }) => ({
                filterKey: `${companyName} ${getShopName(shopId)}`,
                label: getShopName(shopId),
                value: shopId,
              })),
            }))
          : [],
      [companies, getShopName, needsShopOptions],
    );

    const enabledShopsByRate = useMemo(() => {
      if (!isOneOfUnion(condition, "rate")) {
        return [];
      }

      return companies.flatMap(({ shops }) =>
        shops
          .map((shop) => {
            const name = conditionFilters.rate.filter(condition.rate, shop)
              ? getShopName(shop.shopId)
              : null;
            return name ? { shopId: shop.shopId, name } : null;
          })
          .filter(isNotNullish),
      );
    }, [companies, condition, getShopName]);

    const renderTypeSpecific = useCallback(() => {
      if (isOneOfUnion(condition, "all")) {
        return <Input disabled value="全店舗で有効" />;
      }
      if (isOneOfUnion(condition, "rate")) {
        return (
          <Columns>
            <InputNumber<string>
              value={`${condition.rate}`}
              disabled={disabled}
              min="0"
              max="1"
              step="0.01"
              onChange={(next) => setCondition(index, { rate: zeroIfNaN(Number(next)) })}
            />
            <Spacer size={8} inline />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(enabledShopsByRate, null, 2));
                message.success("対象店舗のリストをクリップボードにコピーしました");
              }}
            >
              対象店舗のリストをクリップボードにコピー
            </Button>
            <Spacer size={8} inline />
            <div>{enabledShopsByRate.length} 店舗で有効</div>
          </Columns>
        );
      }

      if (isSomeOfUnion(condition, ["shopIds", "disabledShopIds"])) {
        const key = isOneOfUnion(condition, "shopIds") ? "shopIds" : "disabledShopIds";
        const value = isOneOfUnion(condition, "shopIds")
          ? condition.shopIds
          : condition.disabledShopIds;

        return (
          <ShopSelectContainer>
            <Select<string[]>
              value={value}
              disabled={disabled}
              mode="multiple"
              placeholder="選択してください"
              optionFilterProp="filterKey"
              options={shopOptions}
              onChange={(next) =>
                setCondition(index, key === "shopIds" ? { [key]: next } : { [key]: next })
              }
            />
            <Spacer size={8} />
            <InputCustomFormatText
              value={value}
              disabled={disabled}
              serialize={(data) => JSON.stringify(data)}
              deserialize={(value) => {
                try {
                  const raw = JSON.parse(value);
                  return Array.isArray(raw) ? raw : [];
                } catch {
                  return [];
                }
              }}
              onChange={(next) =>
                setCondition(index, key === "shopIds" ? { [key]: next } : { [key]: next })
              }
            />
          </ShopSelectContainer>
        );
      }

      throw unreachable(condition, "renderTypeSpecific");
    }, [condition, disabled, enabledShopsByRate, setCondition, index, shopOptions]);

    const handleRemove = useCallback(() => removeCondition(index), [index, removeCondition]);
    const handleUp = useCallback(() => moveCondition(index, index - 1), [index, moveCondition]);
    const handleDown = useCallback(() => moveCondition(index, index + 1), [index, moveCondition]);

    return (
      <Container>
        <h4>
          {index}: {Object.keys(condition).at(0)}
        </h4>
        <Columns>
          <Spacer size={16} inline />
          {renderTypeSpecific()}
          <Spacer size={8} inline />
          <Button disabled={disabled} icon={<CaretUpFilled />} onClick={handleUp} />
          <Spacer size={8} inline />
          <Button disabled={disabled} icon={<CaretDownFilled />} onClick={handleDown} />
          <Spacer size={8} inline />
          <Button disabled={disabled} danger icon={<DeleteOutlined />} onClick={handleRemove} />
        </Columns>
      </Container>
    );
  },
);
