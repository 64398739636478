import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { Select } from "components/Input/Select";
import { RecordOfLocalOperation } from "hooks/useCashRegisterLocalOperationRecords/types";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

export type FilterConditions = {
  type?: RecordOfLocalOperation["type"];
  status?: "unsettled" | "resolved" | "disposed";
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  align-items: center;
  margin-bottom: 16px;
`;

type Props = {
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const isRecordTypeType = (value: string): value is RecordOfLocalOperation["type"] => {
  const casted = value as RecordOfLocalOperation["type"];
  return (
    casted === "createOnSitePayment" ||
    casted === "createOrder" ||
    casted === "disposeNotDispatchedData" ||
    casted === "startPlan" ||
    casted === "startTableUser" ||
    (casted satisfies never, false)
  );
};

export const isStatusType = (value: string): value is NonNullable<FilterConditions["status"]> => {
  const casted = value as NonNullable<FilterConditions["status"]>;
  return (
    casted === "unsettled" ||
    casted === "resolved" ||
    casted === "disposed" ||
    (casted satisfies never, false)
  );
};

export const LocalOperationRecordFilter = memo<Props>(
  ({ hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions }) => (
    <Wrapper>
      <Col flex="1 0">
        <Select<string>
          placeholder="種別"
          optionFilterProp="label"
          options={[
            { label: "卓立ち上げ", value: "startTableUser" },
            { label: "プラン開始", value: "startPlan" },
            { label: "注文追加", value: "createOrder" },
            { label: "会計", value: "createOnSitePayment" },
            { label: "データ破棄", value: "disposeNotDispatchedData" },
          ]}
          value={filterConditions.type}
          onChange={(type) =>
            updateFilterCondition({
              type: isRecordTypeType(type) ? type : undefined,
            })
          }
          showSearch
          allowClear
        />
      </Col>
      <Col flex="1 0">
        <Select<string>
          placeholder="状態"
          optionFilterProp="label"
          options={[
            { label: "未解決", value: "unsettled" },
            { label: "解決済み", value: "resolved" },
            { label: "破棄済み", value: "disposed" },
          ]}
          value={filterConditions.status}
          onChange={(status) =>
            updateFilterCondition({
              status: isStatusType(status) ? status : undefined,
            })
          }
          showSearch
          allowClear
        />
      </Col>
      <Col flex="none">
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Col>
    </Wrapper>
  ),
);
